const initBlock = function( $block ) {
	let isAnimating = false;
	const $valuePropsWrap = $block.find( '.value-props' );
	let $valueProps = $block.find( '.value-prop' );
	let direction = 'top';
	const duration = 400;
	const totalItems = $valueProps.length;

	const checkSideItems = ( indexThis, indexPrev ) => {
		if ( ( indexThis === 0 ) || ( indexPrev === 0 ) ) {
			$valuePropsWrap.addClass( 'add-top-space' );
		} else if ( ( indexThis === totalItems - 1 ) || ( indexPrev === totalItems - 1 ) ) {
			$valuePropsWrap.addClass( 'add-bottom-space' );
		}
	};

	const simulateClicks = ( current, target, duration ) => {
		const step = current < target ? 1 : -1;

		const simulateStep = ( currentStep ) => {
			if ( currentStep === target ) {
				$valueProps.filter( `[data-index="${target}"]` ).trigger( 'click' );
			} else {
				$valueProps.filter( `[data-index="${currentStep}"]` ).trigger( 'click' );
				setTimeout( () => {
					simulateStep( currentStep + step );
				}, duration );
			}
		};

		simulateStep( current + step );
	};

	$valueProps.on( 'click', function( e ) {
		if ( $( window ).width() <= 575 ) {
			const $this = $( this );
			if ( isAnimating ) {
				return false;
			}
			let indexThis = +$this.attr( 'data-index' );
			let indexPrev = +$valueProps.filter( '.is-active' ).attr( 'data-index' );
			const clickGap = Math.abs( indexThis - indexPrev );
			if ( clickGap >= 2 ) {
				simulateClicks( indexPrev, indexThis, duration );
				return;
			}

			if ( indexPrev > indexThis ) {
				direction = 'top';
			} else {
				direction = 'bottom';
			}

			checkSideItems( indexThis, indexPrev );
			isAnimating = true;
			e.preventDefault();
			$valuePropsWrap.addClass( 'direction-' + direction );
			$this.addClass( 'is-animating' );

			if ( direction === 'top' ) {
				$this.next().addClass( 'is-animating-prev' );
			} else {
				$this.prev().addClass( 'is-animating-prev' );
			}

			setTimeout( function() {
				$valuePropsWrap.attr( 'data-current', indexThis );
				$valueProps.removeClass( 'is-active' );
			}, duration / 2 );

			setTimeout( function() {
				$this.addClass( 'is-active' );
				$this.removeClass( 'is-animating' );
				$valueProps.removeClass( 'is-animating-prev' );
				$valuePropsWrap.removeClass( 'is-animated direction-top direction-bottom add-top-space add-bottom-space' );
				isAnimating = false;
			}, duration );
		}
	} );
};

themeUtils.loadBlock( initBlock, 'value-props', '.section-value-props' );
